const password = document.querySelectorAll('.password');
const confirmPassword = document.querySelectorAll('.confirm-password');
const passwordProgressBar = document.querySelectorAll('.password-progress-bar');
const confirmPasswordProgressBar = document.querySelectorAll('.confirm-password-progress-bar');
const pswdInfos = document.querySelectorAll('.pswd-infos');
const pswdHelpElt = document.querySelectorAll('.pswd-help');
const pswdConfirmHelpElt = document.querySelectorAll('.pswd-confirm-help');
let pswdHelpMessage;
let value;
let width;
const regexNumber = /\d+/;
const regexLowerCase = /(?=.*[a-z])/;
const regexUppercase = /(?=.*[A-Z])/;
let i;

const HelpPassword = {
    init: function () {
        for (i = 0; i < passwordProgressBar.length; i++) {
            passwordProgressBar[i].style.display = 'none';
        }
        for (i = 0; i < confirmPasswordProgressBar.length; i++) {
            confirmPasswordProgressBar[i].style.display = 'none';
        }
        HelpPassword.events();
    },

    events: function () {
        for (i = 0; i < password.length; i++) {
            password[i].addEventListener('input', function (e) {
                for (i = 0; i < passwordProgressBar.length; i++) {
                    passwordProgressBar[i].style.display = 'block';
                }

                value = e.target.value; // Valeur saisie dans le champ mdp
                width = '33%';

                if (document.documentElement.clientWidth <= 798) {
                    pswdHelpMessage = 'Corta';
                } else {
                    pswdHelpMessage = 'Troppo corta';
                }

                HelpPassword.passwordProgress(value);
            });
        }

        for (i = 0; i < confirmPassword.length; i++) {
            confirmPassword[i].addEventListener('input', function () {
                HelpPassword.confirmProgress();
            });
        }
    },

    passwordProgress: function () {
        if (value.length >= 8) {
            if (regexNumber.test(value)) {
                if (regexUppercase.test(value)) {
                    if (regexLowerCase.test(value)) {
                        if (document.documentElement.clientWidth <= 798) {
                            pswdHelpMessage = 'Forte';
                        } else {
                            pswdHelpMessage = 'La lunghezza della password è adeguata';
                        }
                        for (i = 0; i < pswdHelpElt.length; i++) {
                            pswdHelpElt[i].classList.remove('bg-warning');
                            pswdHelpElt[i].classList.add('bg-success');
                        }
                        width = '100%';
                        for (i = 0; i < pswdInfos.length; i++) {
                            pswdInfos[i].style.display = 'none';
                        }
                    } else {
                        if (document.documentElement.clientWidth <= 798) {
                            pswdHelpMessage = 'Nessuna lettera minuscola';
                        } else {
                            pswdHelpMessage = 'La password non contiene nessuna lettera minuscola';
                        }
                        for (i = 0; i < pswdHelpElt.length; i++) {
                            pswdHelpElt[i].classList.remove('bg-danger');
                            pswdHelpElt[i].classList.add('bg-warning');
                        }
                        width = '66%';
                    }
                } else {
                    if (document.documentElement.clientWidth <= 798) {
                        pswdHelpMessage = 'Nessuna lettara maiuscola';
                    } else {
                        pswdHelpMessage = 'La password non contiene nessuna lettera maiuscola';
                    }
                    width = '66%';
                }
            } else {
                if (document.documentElement.clientWidth <= 798) {
                    pswdHelpMessage = 'Nessun numero';
                } else {
                    pswdHelpMessage = 'La password non contiene nessun numero';
                }

                for (i = 0; i < pswdHelpElt.length; i++) {
                    pswdHelpElt[i].classList.remove('bg-danger');
                    pswdHelpElt[i].classList.add('bg-warning');
                }
                width = '66%';
            }
        } else if (value.length >= 4) {
            pswdHelpMessage = 'Lunghezza insufficiente';
            width = '66%';
        }

        for (i = 0; i < pswdHelpElt.length; i++) {
            pswdHelpElt[i].textContent = pswdHelpMessage;
            pswdHelpElt[i].style.width = width;
        }
    },

    confirmProgress: function () {
        for (i = 0; i < confirmPasswordProgressBar.length; i++) {
            confirmPasswordProgressBar[i].style.display = 'block';
        }

        for (i = 0; i < password.length; i++) {
            var passwordValue = password[i].value;
        }
        for (i = 0; i < confirmPassword.length; i++) {
            var confirmPasswordValue = confirmPassword[i].value;
        }

        if (passwordValue === confirmPasswordValue) {
            for (i = 0; i < pswdConfirmHelpElt.length; i++) {
                if (document.documentElement.clientWidth <= 798) {
                    pswdConfirmHelpElt[i].textContent = 'identiche';
                } else {
                    pswdConfirmHelpElt[i].textContent = 'Password identiche';
                }
                pswdConfirmHelpElt[i].classList.remove('bg-warning');
                pswdConfirmHelpElt[i].classList.add('bg-success');
                pswdConfirmHelpElt[i].style.width = '100%';
            }
        } else {
            for (i = 0; i < pswdConfirmHelpElt.length; i++) {
                if (document.documentElement.clientWidth <= 798) {
                    pswdConfirmHelpElt[i].textContent = 'Conferma';
                } else {
                    pswdConfirmHelpElt[i].textContent = 'Inserisci nuovamente la tua password';
                }
                pswdConfirmHelpElt[i].classList.remove('bg-danger');
                pswdConfirmHelpElt[i].classList.add('bg-warning');
                pswdConfirmHelpElt[i].style.width = '50%';
            }
        }
    }
};
HelpPassword.init();